import React, { PropsWithChildren } from 'react';

import { FlexBox, Image, Layout, Logo, Text } from '@blockworks/ui/components';
import { ColorModeClass } from '@blockworks/ui/models';

import homeScreenshot from '@/assets/images/home.png';
import { useAuth } from '@/context/auth/auth.provider';
import { ILayoutProps } from '@/interfaces/layout';

import UserDropdown from '../global/top-bar/user-dropdown/user-dropdown';
import { LayoutHead } from '../layout-head';

interface IAuthLayoutProps extends PropsWithChildren, Omit<ILayoutProps, 'layoutProps'> {
    sidebarContent?: React.ReactNode;
    sidebarFooterContent?: React.ReactNode;
    sidebarContentPosition?: 'left' | 'right';
    headingText: string;
    subheadingText: string;
    col?: boolean;
    yAlignCenter?: boolean;
}

const sidebarPX = 'pl-[calc(max(calc(100vw_/_2)_-_calc(1440px_/_2),4.5rem))] pr-10';

const AuthLayout = ({
    metaProps,
    additionalScripts,
    sidebarFooterContent,
    sidebarContent,
    headingText,
    sidebarContentPosition = 'right',
    subheadingText,
    col,
    yAlignCenter = false,
    children,
}: IAuthLayoutProps) => {
    const { status } = useAuth();

    const renderSidebarContent = () => (
        <div className={`flex h-[min(400px,100%)] max-w-full pt-6 ${yAlignCenter ? 'items-center' : ''}`}>
            <FlexBox col gap="md" maxW="full">
                <FlexBox col gap="md" pr={5}>
                    <Text size="display-sm" weight="medium">
                        {headingText}
                    </Text>
                    <Text color="deselect" size="md">
                        {subheadingText}
                    </Text>
                </FlexBox>
                {sidebarContent}
            </FlexBox>
        </div>
    );

    return (
        <Layout>
            <LayoutHead metaProps={metaProps} additionalScripts={additionalScripts} />
            <FlexBox h="full" minH="full">
                <div className={`w-[50%] xl:w-[42%] ${ColorModeClass.Dark} hidden lg:flex`}>
                    <FlexBox w="full" col={col} bgColor="muted" color="base" pos="relative" overflow="hidden">
                        <div className={`relative h-full flex items-center w-full ${sidebarPX} pr-10 py-24`}>
                            <div className="absolute top-6">
                                <Logo />
                            </div>
                            {sidebarContentPosition === 'left' ? renderSidebarContent() : null}
                        </div>
                        {sidebarFooterContent ? (
                            <div className={`flex ${sidebarPX} mt-auto mb-10 pr-16`}>{sidebarFooterContent}</div>
                        ) : null}
                        {sidebarContentPosition === 'right' && (
                            <div className="sm:hidden md:block absolute border-8 border-solid border-grey-500 rounded-2xl top-[25%] -right-20 shadow-3xl">
                                <Image
                                    src={homeScreenshot.src}
                                    alt="homepage screenshot"
                                    width="500"
                                    height="500"
                                    borderRadius="md"
                                />
                            </div>
                        )}
                    </FlexBox>
                </div>
                <div className="w-[100%] lg:w-[50%] xl:w-[58%] flex flex-col lg:flex-row">
                    <div className={`w-[100%] flex ${ColorModeClass.Dark} xs:flex lg:hidden`}>
                        <FlexBox w="full" bgColor="muted" color="base" pos="relative" py={6} px={5}>
                            <Logo />
                        </FlexBox>
                    </div>
                    <div className="relative flex justify-center items-center w-full lg:pr-[calc(max(calc(100vw_/_2)_-_calc(1440px_/_2),4.5rem))] px-10 py-12">
                        <div className="w-[min(100%,400px)] max-w-full min-h-[min(400px,100%)] ">
                            {status === 'authenticated' && (
                                <FlexBox justifyContent="start" gap="xl" pb={5}>
                                    <UserDropdown hideMenuLinks />
                                </FlexBox>
                            )}
                            {sidebarContentPosition === 'right' && sidebarContent ? renderSidebarContent() : null}
                            <FlexBox>{children}</FlexBox>
                        </div>
                    </div>
                </div>
            </FlexBox>
        </Layout>
    );
};

export default AuthLayout;
