import { useMutationState } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { useTrack } from '@blockworks/platform/services/analytics';
import { FlexBox, Link } from '@blockworks/ui/components';
import { Form } from '@blockworks/ui/forms/form';

import { AppRoutes } from '@/api/auth/auth.routes';
import { authMutations } from '@/api/auth/mutations';
import { loginSchema } from '@/components/auth/forms/login.schema';
import { useCallbackUrlParam } from '@/hooks/use-callback-url';
import { AnalyticsEvent } from '@/modules/utils/enums/events-tracking';

export const AccountSignInForm = () => {
    const router = useRouter();
    const callbackUrl = useCallbackUrlParam();

    const track = useTrack();
    const { handleSubmit, control } = Form.use({
        values: {
            email: '',
            password: '',
        },
        shouldUseNativeValidation: false,
        resolver: Form.resolvers.yup(loginSchema),
    });

    const anySignIn = useMutationState({ filters: { mutationKey: authMutations.signin.use.getKey() } });
    const signInMutation = authMutations.signin.use({
        onSuccess: () => {
            track(AnalyticsEvent.successfulLogin, { source: router.asPath });
        },
    });

    const onSubmit = handleSubmit(({ email, password }) => {
        signInMutation.mutate({
            provider: 'credentials',
            options: { email, password, redirect: true, callbackUrl },
        });
    });

    return (
        <Form onSubmit={onSubmit} className="w-full">
            <FlexBox col gap="xl" w="full">
                <FlexBox col gap="md" w="full">
                    <Form.Input id="name" name="email" control={control} placeholder="Enter your email address" />
                    <Form.Input
                        id="password"
                        name="password"
                        type="password"
                        control={control}
                        placeholder="Enter your password"
                    />
                    <Link
                        intent="primary"
                        size="sm"
                        href={AppRoutes.SIGN_IN_FORGOT.pathname}
                        disabled={signInMutation.isPending}
                    >
                        Forgot password?
                    </Link>
                </FlexBox>
                <Form.Submit
                    data-testid="sign-in-button"
                    center
                    loading={signInMutation.isPending}
                    disabled={anySignIn.some(({ status }) => ['pending', 'success'].includes(status))}
                >
                    Sign in
                </Form.Submit>
            </FlexBox>
        </Form>
    );
};
