import { Divider, FlexBox, Link, Text } from '@blockworks/ui/components';

import { AccountSignInForm } from '@/components/auth/account-sign-in-form';
import { AuthAlert } from '@/components/auth/auth-alert';
import { OAuthButton } from '@/components/auth/oauth-buttons';
import AuthLayout from '@/layout/auth/auth-layout';
import { IMetaProps } from '@/layout/default-meta';

export const getServerSideProps = async () => {
    return {
        props: {
            meta: {
                title: 'Sign In | Blockworks Research',
            },
        },
    };
};

export const SignInPage = () => {
    return (
        <FlexBox col gap="md" flexGrow={1}>
            <AuthAlert />
            <OAuthButton provider="google">Sign In With Google</OAuthButton>
            <OAuthButton provider="microsoft-entra-id">Sign In With Outlook</OAuthButton>
            <Divider betweenText="or" />
            <AccountSignInForm />

            <FlexBox gap="xs" center w="full">
                <Text size="sm" color={`muted`}>
                    {`Don't have an account?`}
                </Text>
                <Link intent="primary" size="sm" fontWeight="medium" href="/sign-up">
                    Sign up
                </Link>
            </FlexBox>
        </FlexBox>
    );
};

SignInPage.getLayout = (page: React.ReactElement, metaProps: IMetaProps) => (
    <AuthLayout
        metaProps={metaProps}
        headingText="Sign in to your account"
        subheadingText="Crypto's most powerful research platform."
    >
        {page}
    </AuthLayout>
);

export default SignInPage;
