import { AlertProps } from '@blockworks/ui/components';

export enum AuthErrorName {
    CredentialsSignin = 'CredentialsSignin',
    OAuthCallback = 'OAuthCallback',
    Configuration = 'Configuration',
}

export interface AuthAlertShape {
    intent: AlertProps['intent'];
    message: string;
}

export const AuthErrorNameMessage: Record<AuthErrorName, AuthAlertShape> = {
    [AuthErrorName.Configuration]: {
        intent: 'warning',
        message: `Your session has expired, please sign in again.`,
    },
    [AuthErrorName.OAuthCallback]: {
        intent: 'danger',
        message: 'Something went wrong during authentication, please try again.',
    },
    [AuthErrorName.CredentialsSignin]: {
        intent: 'danger',
        message: 'Invalid username or password.',
    },
};
